import { useTranslation } from "next-i18next"

const useFindAStore = () => {
  const { t } = useTranslation()
  const i18n = {
    selectState: t("kf.findstore.label.selectState"),
    selectCity: t("kf.findstore.label.selectCity"),
    buy: t("kf.findstore.label.buy"),
    exclusive: t("kf.findstore.label.exclusive"),
    kohlerExperienceCenter: t("kf.findstore.label.kohlerExperienceCenter"),
    dealer: t("kf.findstore.label.dealer"),
    mbo: t("kf.findstore.label.mbo"),
    studioKohler: t("kf.findstore.label.studioKohler"),
    selectDropdown: t("kf.findstore.message.selectDropdown"),
    notInRegion: t("kf.findstore.message.notInRegion"),
    backToTop: t("kf.favorite.label.backToTop"),
    email: t("kf.share.label.email"),
    mobile: t("kf.findstore.message.mobile"),
    phone: t("kf.findstore.message.phone"),
    liveArea: t("kf.findstore.message.liveArea"),
  }

  return i18n
}

export default useFindAStore

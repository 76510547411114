import { useState, useEffect } from "react"
import { isMobile } from "react-device-detect"
import Accordion from "@/components/core/Accordion/Accordion"
import NoStoreAvailable from "./NoStoreAvailable"
import { getStoresList } from "utils/findStore"
import { contactClickAnalytics, emailClickAnalytics } from "./analytics"
import { pushAnalyticsObjToDataLayer } from "@/utils/helper"

const StoreListing = props => {
  const { storeCity, storeState, staticTexts, storeList, setError, error } =
    props
  const [storeListSeggrgated, setStoreListSeggregated] = useState(null)
  const [accordionOpen, setAccordionOpen] = useState(!isMobile)
  const storeTypes = [
    "kohler experience center",
    "studio kohler",
    "exclusive",
    "dealer",
  ]
  const storeTypesList = {
    "kohler experience center": staticTexts.kohlerExperienceCenter,
    "studio kohler": staticTexts.studioKohler,
    exclusive: staticTexts.exclusive,
    dealer: staticTexts.mbo,
  }

  useEffect(() => {
    seggregateStores(storeList)
    if (storeList?.length === 0) setError()
  }, [storeList])

  useEffect(() => {
    getStoreList()
  }, [storeCity, storeState])
  const seggregateStores = storesListing => {
    const storeListSeggrgated = storesListing?.reduce(function (memo, x) {
      if (!memo[x["storeType"]]) {
        memo[x["storeType"]] = []
      }
      memo[x["storeType"]].push(x)
      return memo
    }, {})
    setStoreListSeggregated(storeListSeggrgated)
  }
  const expandAnalytics = () => {
    pushAnalyticsObjToDataLayer({
      clickInternalLinks: "true",
      eventAction: `find a store:landing:location info:accorddion ${
        accordionOpen ? "collapse" : "expand"
      }`,
      eventName: `find a store:landing:location info:accordion ${
        accordionOpen ? "collapse" : "expand"
      }`,
      eventType: "navigation",
      internalLinkName: `accordion ${accordionOpen ? "collapse" : "expand"}`,
      internalLinkPosition: "find a store",
      internalLinkType: `find a store:exclusive showrooms`,
      internalLinkZoneName: `find a store:landing:location info`,
      internalLinkURL: "n/a",
    })
  }

  const getStoreList = () => {
    try {
      if (storeState && storeCity) {
        const response = getStoresList(storeState, storeCity)
        response.then(resp => {
          const { statusCode, result = {} } = resp
          if (statusCode === 200) {
            const res = result.data.stores
            seggregateStores(res)
          }
          return { statusCode }
        })
      }
    } catch (error) {
      return { statusCode: 400, message: error.message }
    }
  }
  return (
    <div>
      {storeListSeggrgated && Object.keys(storeListSeggrgated).length > 0 ? (
        <>
          <div className="find-a-store__store-city">{storeCity}</div>
          <hr className="find-a-store__separator" />
          <div className="find-a-store__collapsible">
            {storeTypes?.map((store, index) => (
              <>
                {storeListSeggrgated[store]?.length && (
                  <div key={index}>
                    <Accordion
                      contentOverflow="visible"
                      title={storeTypesList[store]}
                      isOpen={!isMobile ? true : false}
                      triggerStyle={!isMobile ? { display: "none" } : null}
                      containerElementProps={{ id: "viewStoreList" }}
                      titleClassName="find-a-store__store-type"
                      expandAnalyticsEvent={expandAnalytics}
                      handleOpen={() => setAccordionOpen(true)}
                      handleClose={() => setAccordionOpen(false)}
                    >
                      <div className="find-a-store__store-details row">
                        {storeListSeggrgated[store]?.map((storeItem, idx) => {
                          return (
                            <div className="find-a-store__store-row" key={idx}>
                              <div className="find-a-store__store-name">
                                {storeItem.storeName.toUpperCase()}
                              </div>
                              {storeItem?.storeOwnerName && (
                                <div className="find-a-store__store-owner-name">
                                  {`${storeItem?.storeOwnerName},`}
                                </div>
                              )}
                              <div className="find-a-store__store-address-primary">
                                {storeItem.addressLine1}
                              </div>
                              {storeItem.addressLine2 && (
                                <div className="find-a-store__store-address-secondary">
                                  {storeItem.addressLine2}
                                </div>
                              )}
                              <div className="find-a-store__store-address-zone">
                                {storeItem.city}, {storeItem.state}
                                {" - "}
                                {storeItem.zip}
                              </div>
                              {storeItem?.mobile.length > 0 && (
                                <div
                                  onClick={() =>
                                    contactClickAnalytics(
                                      storeItem?.mobile[0],
                                      storeItem?.zip,
                                      storeItem?.storeName
                                    )
                                  }
                                >
                                  <span className="find-a-store__store-mobile find-a-store__store-bold">
                                    {`${staticTexts?.mobile} : `}
                                  </span>
                                  {storeItem?.mobile?.map((mobile, index) => {
                                    return (
                                      <a
                                        href={`tel:${mobile}`}
                                        className="find-a-store__store-mobile find-a-store__store-bold"
                                      >
                                        {`${mobile}${
                                          index < storeItem.mobile.length - 1
                                            ? ", "
                                            : ""
                                        }`}
                                      </a>
                                    )
                                  })}
                                </div>
                              )}
                              {storeItem?.phone.length > 0 && (
                                <div>
                                  <span className="find-a-store__store-mobile find-a-store__store-bold">
                                    {`${staticTexts?.phone} : `}
                                  </span>
                                  {storeItem?.phone?.map((phone, index) => {
                                    return (
                                      <a
                                        href={`tel:${phone}`}
                                        className="find-a-store__store-mobile find-a-store__store-bold"
                                      >
                                        {`${phone}${
                                          index < storeItem.phone.length - 1
                                            ? ", "
                                            : ""
                                        }`}
                                      </a>
                                    )
                                  })}
                                </div>
                              )}
                              {storeItem.email.length > 0 && (
                                <div
                                  onClick={() =>
                                    emailClickAnalytics(
                                      storeItem?.email[0],
                                      storeItem?.zip,
                                      storeItem?.storeName
                                    )
                                  }
                                >
                                  <span className="find-a-store__store-mobile find-a-store__store-bold">
                                    {`${staticTexts?.email} : `}
                                  </span>
                                  {storeItem?.email?.map((email, index) => {
                                    return (
                                      <a
                                        href={`mailto:${email}`}
                                        className="find-a-store__store-email"
                                      >
                                        {`${email}${
                                          index < storeItem.email.length - 1
                                            ? ", "
                                            : ""
                                        }`}
                                      </a>
                                    )
                                  })}
                                </div>
                              )}
                              <div className="find-a-store__store-live-area find-a-store__store-bold">
                                {`${staticTexts?.liveArea} :`}{" "}
                                {storeItem.liveArea ? "Yes" : "No"}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </Accordion>
                  </div>
                )}
              </>
            ))}
          </div>
        </>
      ) : (
        <NoStoreAvailable staticTexts={staticTexts} error={error} />
      )}
    </div>
  )
}

export default StoreListing

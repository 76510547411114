import { useEffect, useState } from "react"
import _get from "lodash/get"
import Button from "@/components/core/Button/Button"
import Input from "@/components/core/Input/Input"
import { validateEmail, validNumber, validText } from "@/utils/validate"
import { validateField } from "@/utils/helper"
import { PHONE_REGEX } from "@/constants/index"
import {
  submitLeadGeneration
} from "@/utils/enquiry"
import { submitLeadGenerationAnalytics } from "@/components/LeadGeneration/analytics"
import Dropdown from "@/components/core/SelectDropdown/SelectDropdown"
import { isMobile } from "react-device-detect"
import moment from "moment"

const Enquiry = props => {
  const { staticText, setConfirmationSuccess, authorData, stateWithCities } =
    props

  const [error, setError] = useState({})
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [numberOfBathrooms, setNumberOfBathrooms] = useState([])
  const [city, setCity] = useState([])
  useEffect(() => {
    const cities = authorData?.citiesAvailableItems?.map(item => {
      return item.citiesAvailable
    })
    const numberOfBathroom = authorData?.quantityAvailableItems?.map(item => {
      return item.quantity
    })
    setNumberOfBathrooms(numberOfBathroom)
    setCity(cities)
  }, [])
  const onNameChange = e => {
    const { value } = e.target
    if (value === "" || validText(value)) {
      setData({
        ...data,
        name: value,
      })
    }
  }
  const onBlur = field => {
    setError({
      ...error,
      [field]: validateExist(field),
    })
  }

  const validateExist = field => {
    if (field === "name") {
      if (!data[field]?.trim() || !validateField("name", data[field])) {
        return staticText?.nameError
      } else return ""
    } else if (field === "phone") {
      if (data?.phone?.trim() && !PHONE_REGEX.test(data?.phone)) {
        return staticText?.errorMobileNumber
      } else if (!authorData.kecFormFlag && !data?.phone?.trim()) {
        return staticText?.errorMobileNumber
      }
    } else if (field === "email") {
      if (!data[field]) return ""
      else if (
        !validateField("email", data[field]) ||
        !validateEmail(data[field])
      )
        return staticText?.validEmailId
    } else if (field === "city") {
      if (authorData.kecFormFlag && !data[field]?.trim())
        return staticText?.validCity
    }
  }
  const onChangeEmailId = e => {
    const { value } = e.target
    setData({
      ...data,
      email: value,
    })

    if (value) {
      if (validateEmail(value)) {
        setError({
          ...error,
          email: "",
        })
      } else {
        setError({
          ...error,
          email: staticText.validEmailId,
        })
      }
    }
  }
  const handleSubmit = async () => {
    const errorList = getErrorList()
    setIsLoading(true)
    let errorDataList = []
    setError(errorList)
    if (errorList?.name) {
      errorDataList.push(`name`)
    }
    errorDataList.forEach((item, i) => {
      if (i === 0) document.getElementById(`${item}`)?.focus()
    })
    const isInvalid = Object?.values(errorList)?.find(el => el)
    if (!isInvalid) {
      const { name, phone, email, city, state, numberOfBathrooms } = data
      if (authorData?.kecFormFlag) {
        const date = moment(new Date())?.format("DD/MM/YYYY")
        const payload = {
          name: name?.trim(),
          ...(phone && { mobileNumber: phone }),
          email: email ? [email] : [],
          city: city,
          state: state,
          ...(numberOfBathrooms && { numberOfBathrooms: numberOfBathrooms }),
          date: date,
        }
        const response = await submitLeadGeneration({payload, isKECForm: true})
        if (response.statusCode === 200) {
          submitLeadGenerationAnalytics("success")
          setConfirmationSuccess(true)
        } else {
          submitLeadGenerationAnalytics("failure", "form submission failed")
          setConfirmationSuccess(false)
        }
        setIsLoading(false)
      } else {
        const payload = {
          name: name?.trim(),
          contactNumber: phone,
        }
        const response = await submitLeadGeneration({payload})
        if (response.statusCode === 200) {
          submitLeadGenerationAnalytics("success")
          setConfirmationSuccess(true)
        } else {
          submitLeadGenerationAnalytics("failure", "form submission failed")
          setConfirmationSuccess(false)
        }
        setIsLoading(false)
      }
    } else {
      submitLeadGenerationAnalytics("failure", "form validation failed")
    }
    setIsLoading(false)
  }

  const getErrorList = () => {
    const errorList = {
      name: validateExist("name"),
      phone: validateExist("phone"),
      city: validateExist("city"),
    }
    return errorList
  }

  const isEnabled = Object?.values(getErrorList())?.find(el => el)
    ? true
    : false

  const handleChangeNumber = event => {
    const { value } = event.target
    if (value === "" || validNumber(value)) {
      setData({
        ...data,
        phone: value,
      })
      setError({
        ...error,
        phone: "",
      })
    }
  }
  const handleNumberOfBathrooms = numberOfBathrooms => {
    setData({ ...data, numberOfBathrooms })
  }
  const handleCityChange = city => {
    const stateName = stateWithCities?.filter(ele => {
      return ele?.cityName?.includes(city)
    })?.[0]?.stateName
    setData({ ...data, state: stateName, city: city })
    setError({
      ...error,
      city: "",
    })
  }
  return (
    <div className="lead-generation__margin-div">
      <Input
        id="lead-generation-name"
        type="text"
        placeholder={"Name*"}
        label={"Name*"}
        value={data?.name ?? ""}
        onChange={onNameChange}
        onInput={onNameChange}
        showError={error?.name !== "" && error?.name}
        errorMessage={error?.name}
        onBlur={() => onBlur("name")}
        autoComplete={false}
        maxLength={40}
      />
      {authorData.kecFormFlag ? (
        <Input
          id="contact-user-emailId"
          type="text"
          placeholder={staticText?.email}
          label={staticText?.email}
          value={data?.email ?? ""}
          onChange={onChangeEmailId}
          showError={error?.email}
          errorMessage={error?.email}
          maxLength={60}
          onBlur={() => onBlur("email")}
          autoComplete={false}
          prefix={<img src={"/icons/email.svg"} alt="" aria-hidden="true" />}
        />
      ) : null}
      <Input
        id="lead-generation-phone"
        type="text"
        placeholder={
          authorData.kecFormFlag
            ? staticText.phoneNumber
            : staticText.mobileNumber
        }
        label={
          authorData.kecFormFlag
            ? staticText.phoneNumber
            : staticText.mobileNumber
        }
        value={data?.phone ?? ""}
        maxLength={10}
        autoComplete={false}
        onChange={handleChangeNumber}
        showError={error?.phone}
        errorMessage={error?.phone}
        onBlur={() => onBlur("phone")}
        prefix={<img src={"/icons/phone-icon.svg"} alt="" aria-hidden="true" />}
      />

      {authorData.kecFormFlag ? (
        <div className="mb-20">
          <Dropdown
            id="lead-form-numberOfBathrooms"
            data={numberOfBathrooms}
            value={data.numberOfBathrooms ?? ""}
            label={"Number of Bathrooms that require Kohler products"}
            onChange={handleNumberOfBathrooms}
          />
        </div>
      ) : null}
      {authorData.kecFormFlag ? (
        <div className="row mx-0">
          <div
            className={`col-12 col-sm-6 col-md-6 pl-0 ${
              isMobile ? "pr-0" : "pr-10"
            }`}
          >
            <Dropdown
              customClass="customer-support-inputfield"
              id="lead-form-city"
              type="text"
              placeholder={staticText?.city}
              label={staticText?.city}
              value={data.city}
              data={city}
              onChange={handleCityChange}
              showError={error.city}
              errorMessage={error.city}
              onBlur={() => onBlur("city")}
            />
          </div>
          <div
            className={`col-12 col-sm-6 col-md-6 pr-0 ${
              isMobile ? "pl-0" : "pl-10"
            }`}
          >
            <Input
              customClass="customer-support-inputfield"
              id="contact-user-state"
              type="text"
              placeholder={staticText?.state}
              label={staticText?.state}
              value={data.state}
              showError={error.state}
              errorMessage={error.state}
              disabled
            />
          </div>
        </div>
      ) : null}

      <div className="lead-generation__buttonDiv">
        <Button
          type="black"
          role="button"
          label={
            authorData.kecFormFlag
              ? staticText.scheduleAVisit
              : staticText.submit
          }
          className="lead-generation__button"
          flexible
          onClick={handleSubmit}
          disabled={isEnabled}
          loading={isLoading}
        />
      </div>
      <div className="lead-generation__privacyText">
        {staticText?.privacyText}
      </div>
    </div>
  )
}

export default Enquiry

import Button from "@/components/core/Button/Button"
import DOMPurify from "isomorphic-dompurify"
import styles from "@/components/LeadGeneration/LeadGenerationView/index.module.scss"
import { backToHomeAnalytics } from "@/components/LeadGeneration/analytics"

const Confirmation = props => {
  const { staticText, setConfirmationSuccess } = props
  const onClick = () => {
    setConfirmationSuccess(false)
  }
  return (
    <div className={styles.leadGenerationLanding}>
      <div className="lead-generation__success-box">
        <div className="lead-generation__success-description">
          {staticText.messageDescriptionSuccess}
        </div>
        <div className="container">
          <Button
            customClass={"kf-react-button"}
            className={"kf-react-button button large black flexible"}
            role="button"
            id="submit-contact-form-button"
            onClick={onClick}
            type="black"
            label={staticText?.backToForm}
          />
        </div>
      </div>
    </div>
  )
}

export default Confirmation
